@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/spacing';

.checkbox-list {
  margin: 0;
  padding: 0;
  border: none;

  &__item-wrapper {
    margin-top: spacing.$l;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: spacing.$m;

    @media (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    border-bottom: 1px solid light.$ge-divider-default;
    padding-bottom: spacing.$s;
  }
}
